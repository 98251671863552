import * as React from 'react';
import { graphql } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';

import { repositoryConfigs } from '../utils/prismicPreview';

import Layout from '../components/Layout';
import SliceZone from '../components/SliceZone';

const PageTemplate = ({ data }) => {
  if (!data) {
    return null;
  }

  const pageContent = data.prismicPage;
  const page = pageContent.data || {};

  const { lang, type, url } = pageContent;
  const alternateLanguages = pageContent.alternate_languages || [];
  const activeDoc = {
    lang,
    type,
    url,
    alternateLanguages,
  };

  return (
    <Layout
      customSEO={{
        title: page.title.text,
        description: page.description.text,
        image: page.social_preview_image.url
      }}
      activeDoc={activeDoc}
    >
      <SliceZone slices={page.body} />
    </Layout>
  );
};

export const query = graphql`
  query pageQuery($id: String, $lang: String) {
    prismicPage(id: { eq: $id }, lang: { eq: $lang }) {
      _previewable
      url
      type
      lang
      alternate_languages {
        id
        type
        lang
        uid
      }
      data {
        body {
          ...PageDataBody
        }
        title {
          ...TextContent
        }
        description {
          ...TextContent
        }
        social_preview_image {
          url
        }
      }
    }
  }
`;

export default withPrismicPreview(PageTemplate, repositoryConfigs);
